import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'rtpca-page-not-found',
    templateUrl: './page-not-found.component.html',
    imports: [TranslateModule]
})
export class PageNotFoundComponent {
}
