import { CommonModule, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { from, mergeAll } from 'rxjs';

import { TranslateModule } from '@ngx-translate/core';

import { selectLoading as dataCalendarProcessing } from '../../features/data-calendar/store/reducers';
import { selectLoading as programConfigProcessing } from '../../features/program-config/store/reducers';
import { selectLoading as mappingProcessing } from '../../features/user-mapping/store/reducers';
import { selectLoading as budgetProcessing } from '../../features/budgets/store/reducers';

@Component({
    selector: 'rtpca-spinner',
    imports: [NgIf, TranslateModule, CommonModule],
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinnerComponent {
  loading$ = from([
    this.store.select(mappingProcessing),
    this.store.select(programConfigProcessing),
    this.store.select(dataCalendarProcessing),
    this.store.select(budgetProcessing),
  ]).pipe(mergeAll());

  constructor(private readonly store: Store) {}
}
